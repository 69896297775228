const scalaCompanies = {
}

const functionalCompanies = {
};
const kotlinCompanies = {};

const typeScriptCompanies = {
};

const rustCompanies = {
};

const wfhCompanies = {
};

const companies = process.env.WEBSITE === "functionaljobs" ? functionalCompanies :
  process.env.WEBSITE === "kotlinjobs" ? kotlinCompanies :
    process.env.WEBSITE === "typescriptjobs" ? typeScriptCompanies :
      process.env.WEBSITE === "wfhjobs" ? wfhCompanies :
        process.env.WEBSITE === "rustjobs" ? rustCompanies :
          scalaCompanies;

export default companies;